import * as Sentry from '@sentry/gatsby';
import Vimeo from '@vimeo/player';
import '@fontsource/raleway';
import '@fontsource/heebo';
import '@fontsource/heebo/200.css';
import '@fontsource/heebo/300.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const { CustomProvider } = require('./custom-context');
const React = require('react');

export const onRouteUpdate = ({ location, prevLocation }) => {
	if (typeof window !== 'undefined') {
		// Ensure scrolling resets on every page load, unless navigating to a hash
		if (!location?.hash?.startsWith('#')) {
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: 'smooth' });

				// Optional: Ensure elements exist before scrolling
				const bodyChildren = document.getElementById('bodyChildren');
				const gatsbyWrapper = document.getElementById('gatsby-focus-wrapper');

				if (bodyChildren) {
					bodyChildren.scrollIntoView({ behavior: 'smooth' });
				}
				if (gatsbyWrapper) {
					gatsbyWrapper.scrollIntoView({ behavior: 'smooth' });
				}
			}, 100); // Small delay ensures the DOM is ready
		}
	}

	// Initialize Vimeo tracking
	try {
		const iframes = document.querySelectorAll('iframe');
		iframes.forEach((iframe) => {
			const player = new Vimeo(iframe);
			if (window.analytics && window.analytics.ready) {
				window.analytics.ready(() => {
					const VimeoAnalytics = window.analytics.plugins?.VimeoAnalytics;
					if (VimeoAnalytics) {
						window.analytics.register(VimeoAnalytics);
						const vimeoAnalytics = new VimeoAnalytics(
							player,
							'c7ab22279d452aabcbdc96c2fc28ffc9'
						);
						vimeoAnalytics.initialize();
					}
				});
			}
		});
	} catch (error) {
		console.error('Vimeo Analytics Error:', error);
	}
};

export function wrapPageElement({ element, props }) {
	return <CustomProvider {...props}>{element}</CustomProvider>;
}
