import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React, { useState, useContext } from 'react';
import { navigate, Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import Search from '../Search';
import SubMenu from './subMenu';
import ImageComponent from '../../utils/imageComponent';
import TopMenu from './topMenu';
import LinkComponent from '../../utils/linkComponent';
import { isExecutive } from '../../lib/helpers';
import { NavDropdown } from 'react-bootstrap';
import CustomContext from '../../../custom-context';

const searchIndices = [
	{ name: `Pages`, title: `Pages` },
	{ name: `Resources`, title: `Resources` },
	{ name: `Events`, title: `Events` },
	{ name: `Offers`, title: `Offers` },
	{
		name: `PublicSurveyResults`,
		title: `Survey Results`,
	},
];

const StyledLink = styled((props) => <LinkComponent {...props} />)`
	font-size: var(--font-size-base);
	margin: 0em 0.7em;
	padding: 10px 0px;
	color: var(--secondary_2);
	text-decoration: none;
	white-space: nowrap;
	align-self: center;
	@media (max-width: 1199.5px) {
		font-weight: 600;
		margin-left: 0em;
		align-self: start;
		.mainMenu {
			top: 0px !important;
		}
	}
`;

const NavStyled = styled.div`
	position: sticky;
	top: -1px;
	z-index: 1000;

	.logo {
		width: 200px;
		height: 42px;
	}
	.navbar-light .navbar-nav .nav-link {
		font-size: var(--font-size-base);
	}
	.nav-link {
		background-color: var(--primary);
		color: var(--secondary_8) !important;
		width: 7em;
		text-align: center;
		padding-top: 5px;
		height: 40px;
	}
	.mainMenu {
		background: var(--secondary_6);
		position: sticky;
		top: 0;
	}
	.navbar-light .navbar-brand {
		color: var(--secondary_6);
	}
	.navbar-light .navbar-toggler {
		border-color: var(--secondary_7) !important;
		color: var(--secondary_2) !important;
	}
	.topmenu-dropdown {
		display: inline-grid;
	}
	.loginButton {
		background-color: var(--primary);
		color: var(--secondary_6);
		width: 7em;
		text-align: center;
		font-size: 16px;
		margin-left: 1em;
		padding: 0.3em;
		cursor: pointer;
	}

	.login-menu-button {
		/* Additional styling if needed */
	}
	.hamburger-login-wrapper {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}
	.dropdown-menu {
		background-color: var(--primary);
	}
	.nav-item.dropdown:hover .dropdown-menu {
		display: block;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		color: var(--secondary_6);
		background-color: var(--primary);
	}
	.hamburger-icon {
		padding-left: 0.5em;
	}
	.logout {
		height: 40px;
	}
	@media (max-width: 1199.5px) {
		.top-menu-container {
			display: none;
		}
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			width: 100%;
		}
	}
	@media (max-width: 768px) {
		.navItem {
			border-bottom: 1px solid #ccc !important;
			border-radius: 0px;
			padding: 10px 0px;
			width: 100%;
		}
		.nav-link {
			width: 5em;
		}
		.logo {
			width: 170px;
		}
		.loginButton {
			width: auto;
			padding: 0.5em 1em;
			font-size: 14px;
		}
		/* Make the collapsed menu scrollable when needed */
		.navbar-nav {
			overflow-y: auto;
			max-height: calc(100vh - 150px); /* Adjust offset as needed */
		}
	}
	@media (min-width: 1199.5px) {
		.topmenu-dropdown {
			display: none;
		}
		.dashboard-header-option {
			display: none;
		}
		.nav-link {
			padding-top: 8px;
		}
		.logout {
			padding-top: 8px;
		}
	}
	@media (min-width: 1199.5px) {
		.login-menu-button {
			display: none;
		}
	}
`;

const Header = () => {
	const [expanded, setExpanded] = useState(false);
	const { user, handleLogout } = useContext(CustomContext);

	const menuDataAndLogoData = useStaticQuery(graphql`
		query menuQueryAndStarpiAssetsLogo {
			strapiAsset {
				logo {
					alternativeText
					caption
					url
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
			allStrapiNavigationPluginMainNavigation(sort: { order: ASC }) {
				nodes {
					items {
						path
						title
						type
						sub_menu_icon
						items {
							path
							title
							type
						}
					}
					order
					title
					type
					path
				}
			}
			allStrapiNavigationPlugin3(sort: { order: ASC }) {
				nodes {
					items {
						path
						title
						type
						sub_menu_icon
						items {
							path
							title
							type
						}
					}
					order
					title
					type
					path
				}
			}
		}
	`);

	return (
		<NavStyled>
			<div className='top-menu-container'>
				<TopMenu menu={menuDataAndLogoData.allStrapiNavigationPlugin3.nodes} />
			</div>
			<Navbar
				variant='light'
				expand='xl'
				className='mainMenu'
				collapseOnSelect
				expanded={expanded}
				onToggle={() => setExpanded((prevExpanded) => !prevExpanded)}
			>
				<Container>
					<Navbar.Brand
						onClick={() => {
							setExpanded(false);
							navigate('/');
						}}
						style={{ cursor: 'pointer' }}
					>
						<ImageComponent
							imageItem={menuDataAndLogoData.strapiAsset.logo}
							alt='Logo'
							className='logo'
							loading='eager'
						/>
					</Navbar.Brand>
					<span className='hamburger-login-wrapper'>
						<div className='login-menu-button'>
							{user !== null && user !== undefined ? (
								<div
									className='loginButton logout'
									onClick={() => {
										handleLogout();
									}}
									onKeyDown={() => {
										handleLogout();
									}}
									role='button'
									tabIndex='0'
								>
									Logout
								</div>
							) : (
								<NavDropdown
									align='end'
									id='nav-dropdown-basic'
									title='Login'
									variant='primary'
									renderMenuOnMount={true}
								>
									<NavDropdown.Item
										href={`${process.env.GATSBY_AAF_LOGIN_URL}`}
										className='loginButton'
										onClick={() =>
											sessionStorage.setItem('returnURL', window.location.href)
										}
									>
										Login via AAF
									</NavDropdown.Item>

									<NavDropdown.Item
										href={`${process.env.GATSBY_TUAKIRI_LOGIN_URL}`}
										className='loginButton'
										onClick={() =>
											sessionStorage.setItem('returnURL', window.location.href)
										}
									>
										Login via Tuakiri
									</NavDropdown.Item>
									<NavDropdown.Item>
										<LinkComponent className='loginButton' to='/login-help/'>
											Login Help
										</LinkComponent>
									</NavDropdown.Item>
								</NavDropdown>
							)}
						</div>
						<div className='hamburger-icon'>
							<Navbar.Toggle aria-controls='basic-navbar-nav' />
						</div>
					</span>
					<Navbar.Collapse id='header-basic-navbar-nav'>
						<Nav className='ms-auto topmenu-scroll'>
							<Search setExpanded={setExpanded} indices={searchIndices} />

							{user !== null && user !== undefined && isExecutive(user) ? (
								<div className='dashboard-header-option navItem'>
									<StyledLink
										to='/dashboard/executive'
										onClick={() => setExpanded(false)} // Ensure the dropdown closes
									>
										Dashboard
									</StyledLink>
								</div>
							) : null}

							{menuDataAndLogoData.allStrapiNavigationPluginMainNavigation.nodes.map(
								(menuItem) =>
									menuItem.items?.length > 0 ? (
										<SubMenu
											setExpanded={setExpanded}
											menuItem={menuItem}
											key={menuItem.title}
										/>
									) : (
										<StyledLink
											onClick={() => setExpanded(false)}
											className='navItem'
											to={menuItem.path}
											key={menuItem.title}
										>
											{menuItem.title}
										</StyledLink>
									)
							)}
							<div className='topmenu-dropdown'>
								<div className='topmenu-dropdown'>
									{menuDataAndLogoData.allStrapiNavigationPlugin3.nodes.map(
										(item) => (
											<StyledLink
												to={`${item.path}`}
												key={item.title}
												className='navItem'
												onClick={() => setExpanded(false)}
											>
												{item.title}
											</StyledLink>
										)
									)}
								</div>
							</div>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</NavStyled>
	);
};

export default Header;
